/****************************************************************************
 * Copyright 2021 EPAM Systems
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 ***************************************************************************/

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 40;
  background: rgba(119, 119, 119, 0.2) url('./overlay.svg');
}

.modalOverlay .modalWindow {
  padding: 10px;
  outline: 0;
  position: relative;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  background-clip: padding-box;
  opacity: 1;
  transform: scale(1);
  transition: transform 0.3s, opacity 0.3s;
}

.modalWindow > * {
  margin: 0 15px;
  max-height: 65vh;
}

footer .ok {
  background: #3e7bfa;
  width: 40px;
  color: #fff;
  margin-right: 2px;
  margin-left: 10px;
  border-radius: 2px;
  border: none;
  height: 26px;
}

.ok:hover {
  background: #3265cd;
}

.modalWindow header {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  line-height: 1.3;
  border-radius: 0.7em 0.7em 0 0;
  height: 40px;
}

.modalWindow footer {
  clear: both;
  border-radius: 0 0 0.7em 0.7em;
  text-align: right;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modalBody {
  padding: 5px;
}

.modalHidden {
  display: none;
}
